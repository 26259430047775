<template>
  <div class="view-user">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "PlansIndex"
};
</script>
<style>
.view-user {
  height: 100%;
  overflow: hidden;
}
</style>
